<template lang="pug">
    .content__main
        #notifications
            notifications-module(type="full")
</template>

<script>
import NotificationsModule from "../components/app/notifications-module/notifications-module";
export default {
    components: { NotificationsModule },
    props: {
        type: {
            type: String,
            require: true,
            default: "full",
        },
        notificationTab: {
            type: String,
            require: true,
            default: () => "",
        },
    },
    mounted() {
        this.$store.dispatch("setPage", "notifications");
    },
};
</script>
